import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { formatCurrency } from "../../components/tools";
import { useParams, useNavigate } from "react-router-dom";
import { ChangeCustomerCode, ChangeCustomerCompany, DeleteOneCustomer, GetOneCustomer } from "../../actions/customers";
import NoteField from "../../components/NoteField";
import { GetEmployees } from "../../actions/auth";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { Input, Modal } from "antd";
import { IconButton } from "../../components/buttons";
import { EditPencil } from "iconoir-react";
import CustomerNote from "./customerNote";

const CustomerID = ({ authState, authDispatch }) => {
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noteField, setNoteField] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [codeModal, setCodeModal] = useState(false);
  const [companyModal, setCompanyModal] = useState(false);
  const [newCode, setNewCode] = useState("");
  const [newCompany, setNewCompany] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);

  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneCustomer(id)
        .then((res) => {
          setCustomer(res.data);
          GetEmployees()
            .then((res) => {
              setEmployees(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              console.log(err);
              setTimeout(() => setLoading(false), 700);
            });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data ? err.response.data.message : "Something went wrong, please refresh the page and try again");
          navigate("/customers");
        });
    }
    return () => {
      inView = false;
    };
  }, [id]);

  const reloadData = () => {
    setLoading(true);
    GetOneCustomer(id)
      .then((res) => {
        setCustomer(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please refresh the page and try again");
        setTimeout(() => setLoading(false), 700);
      });
  };

  let tabs = [
    { name: "Overview", href: `/customers/${id}`, current: true },
    { name: "Contact Info", href: `/customers/${id}/contact`, current: false },
    { name: "A/R Info", href: `/customers/${id}/ar`, current: false },
    { name: "Defaults", href: `/customers/${id}/defaults`, current: false },
    { name: "Equipment", href: `/customers/${id}/equipment`, current: false },
    { name: "Quotes", href: `/customers/${id}/quotes`, current: false },
    { name: "Jobs", href: `/customers/${id}/jobs`, current: false },
    { name: "Invoices", href: `/customers/${id}/invoices`, current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const CustomerNavigation = () => {
    return (
      <div className="flex flex-row items-center justify-between w-full gap-3">
        <div className="w-full sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500"
            defaultValue={tabs.find((tab) => tab.current).href}
            onChange={(e) => navigate(e.target.value)}
          >
            {tabs.map((tab) => (
              <option value={tab.href}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden w-full sm:block">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current ? "border-blue-500 text-blue-600" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium",
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    );
  };

  const terms = {
    net10: "Net 10",
    net20: "Net 20",
    net30: "Net 30",
    collectOnDelivery: "Collect on Delivery",
    dueOnReceipt: "Due on Receipt",
    prePay: "Pay in Advance",
  };

  const closeNewCode = () => {
    setCodeModal(false);
    setNewCode("");
  };

  const submitNewCode = () => {
    if (!newCode || newCode.length < 3) {
      toast.error("Customer code must be at least 3 characters long");
    } else {
      setLoading(true);
      ChangeCustomerCode({ customerCode: newCode }, id)
        .then((res) => {
          toast.success("Customer code changed successfully!");
          closeNewCode();
          GetOneCustomer(id)
            .then((res) => {
              setCustomer(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.response.data ? err.response.data.message : "Something went wrong, please refresh the page and try again");
              navigate("/customers");
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please refresh the page and try again");
          setLoading(false);
        });
    }
  };

  const closeNewCompany = () => {
    setCompanyModal(false);
    setNewCompany("");
  };

  const submitNewCompany = () => {
    if (!newCompany || newCompany.length < 3) {
      toast.error("Customer company / name must be at least 3 characters long");
    } else {
      setLoading(true);
      ChangeCustomerCompany({ company: newCompany }, id)
        .then((res) => {
          toast.success("Customer company / name changed successfully!");
          closeNewCompany();
          GetOneCustomer(id)
            .then((res) => {
              setCustomer(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.response.data ? err.response.data.message : "Something went wrong, please refresh the page and try again");
              navigate("/customers");
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please refresh the page and try again");
          setLoading(false);
        });
    }
  };

  const renderChangeCodeModal = () => {
    return (
      <Modal open={codeModal} onCancel={closeNewCode} onOk={submitNewCode} title="Change Customer Code" centered destroyOnClose width={650}>
        <div className="flex flex-col items-start justify-start w-full gap-3 py-4">
          <p>New Customer Code</p>
          <Input
            placeholder="Enter new customer code"
            onChange={(e) => {
              setNewCode(e.target.value);
            }}
            className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
          />
        </div>
      </Modal>
    );
  };

  const renderChangeCompanyModal = () => {
    return (
      <Modal open={companyModal} onCancel={closeNewCompany} onOk={submitNewCompany} title="Change Customer Company / Name" centered destroyOnClose width={650}>
        <div className="flex flex-col items-start justify-start w-full gap-3 py-4">
          <p>New Customer Company / Name</p>
          <Input
            placeholder="Enter new customer company / name"
            onChange={(e) => {
              setNewCompany(e.target.value);
            }}
            className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
          />
        </div>
      </Modal>
    );
  };

  const renderConfirmDelete = () => {
    return (
      <Modal
        open={confirmDelete}
        onCancel={() => setConfirmDelete(false)}
        onOk={submitDelete}
        title="Delete Customer - IRREVERSIBLE"
        centered
        destroyOnClose
        width={650}
      >
        <div className="flex flex-col items-start justify-start w-full gap-3 py-4">
          <p>Are you 100000000000% sure you want to delete this customer?</p>
          <p>This action is final and irreversible, act with uttermost caution.</p>
        </div>
      </Modal>
    );
  };

  const submitDelete = () => {
    setLoading(true);
    DeleteOneCustomer(id)
      .then((res) => {
        toast.success("Customer deleted successfully!");
        navigate("/customers");
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Something went wrong, please refresh the page and try again");
        setLoading(false);
      });
  };

  return (
    <div className="grid w-full h-full grid-cols-12 mb-4 overflow-y-auto md:px-4 gap-x-2">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full col-span-9">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="flex flex-col items-start justify-start w-full col-span-12 md:col-span-9">
          <Helmet>
            <title>
              Customer Overview - {customer.customerCode}
              {customer.company.length > 0 ? ` - ${customer.company}` : ""} | HTPS ERP
            </title>
          </Helmet>
          <CustomerNavigation />
          <div className="mt-3 flex flex-col md:flex-row md:justify-between md:items-center items-start justify-start w-full px-3 pb-3.5 border-b border-gray-300">
            <h1 className="text-2xl md:text-[28px] leading-[42px]">Customer Overview</h1>
            <div className="grid items-center grid-cols-2 gap-1 md:flex md:justify-end md:flex-row">
              <p className="text-xs font-bold uppercase">Company:</p>
              <p className="font-light md:pr-2 md:mr-1.5">{customer.company.length > 0 ? customer.company : "N/A"}</p>
              <IconButton icon={<EditPencil className="w-auto h-4" />} callback={() => setCompanyModal(true)} className="px-2 ml-1" />
              <span className="hidden h-[18px] w-[1px] mx-3 bg-gray-400 md:block"></span>
              <p className="text-xs font-bold uppercase">Customer Code:</p>
              <p className="font-light">{customer.customerCode}</p>
              <IconButton icon={<EditPencil className="w-auto h-4" />} callback={() => setCodeModal(true)} className="px-2 ml-1" />
            </div>
          </div>
          <div className="grid w-full grid-cols-1 grid-rows-1 gap-1 px-3 pb-2 my-2 border-b border-gray-300 md:grid-cols-2">
            <div className="flex items-center gap-1 md:justify-start md:flex-row">
              <p className="text-sm font-bold uppercase md:text-xs">Total Quotes:</p>
              <p className="text-sm font-light">{customer.totalQuotes}</p>
            </div>
            <div className="flex items-center gap-1 md:flex-row md:justify-end">
              <p className="text-sm font-bold uppercase md:text-xs">Customer Since:</p>
              <p className="text-sm font-light uppercase">{dayjs(customer.customerSince).format("MMMM[,] YYYY")}</p>
            </div>
            <div className="flex items-center gap-1 md:justify-start md:flex-row">
              <p className="text-sm font-bold uppercase md:text-xs">Total Jobs:</p>
              <p className="text-sm font-light">{customer.totalJobs}</p>
            </div>
            <div className="flex items-center gap-1 md:flex-row md:justify-end">
              <p className="text-sm font-bold uppercase md:text-xs">Lifetime Spend:</p>
              <p className="text-sm font-light">{formatCurrency(customer.lifetimeSpend)}</p>
            </div>
            <div className="flex items-center gap-1 md:justify-start md:flex-row">
              <p className="text-sm font-bold uppercase md:text-xs">Total Invoices:</p>
              <p className="text-sm font-light">{customer.totalInvoices}</p>
            </div>
            <div className="flex items-center gap-1 md:flex-row md:justify-end">
              <p className="text-sm font-bold uppercase md:text-xs">Outstanding Items:</p>
              <p className="text-sm font-light">{customer.outstandingItems}</p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between w-full px-3 pb-6 mt-3 border-b border-gray-300">
            <h1 className="text-xl text-gray-500 uppercase font-base">Quick Actions</h1>
            <div className="flex flex-row items-center justify-end gap-5">
              <button
                className="px-10 py-3 text-xs font-bold uppercase duration-150 rounded-full bg-slate-300 text-slate-800 hover:bg-gray-500/50"
                onClick={() => navigate(`/quotes/new?customerId=${id}`)}
              >
                New Quote
              </button>
              <button
                className="px-10 py-3 text-xs font-bold uppercase duration-150 rounded-full bg-slate-300 text-slate-800 hover:bg-gray-500/50"
                onClick={() => navigate(`/jobs/new?customerId=${id}`)}
              >
                New Job
              </button>
              {/* <button className="px-10 py-3 text-xs font-bold uppercase duration-150 rounded-full bg-slate-300 text-slate-800 hover:bg-gray-500/50">
                New Invoice
              </button> */}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start w-full px-3 pt-1 pb-4 mt-3 border-b border-gray-300">
            <div className="flex flex-row items-center justify-between w-full">
              <h1 className="text-gray-500 uppercase md:text-xl font-base">Contact Information</h1>
              <button
                className="px-8 py-2 text-xs font-bold uppercase duration-150 rounded-full bg-slate-300 text-slate-800 hover:bg-gray-500/50"
                onClick={() => navigate(`/customers/${id}/contact`)}
              >
                {authState.user.functionCategory === "technician" ? "View" : "View / Edit"}
              </button>
            </div>
          </div>
          <div className="grid w-full grid-cols-1 grid-rows-1 gap-1 px-3 pb-2 my-2 border-b border-gray-300 md:grid-cols-2">
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="w-1/4 text-xs font-bold uppercase">Name:</p>
              <p className="text-sm font-light">{customer.contact.firstName + " " + customer.contact.lastName}</p>
            </div>
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="w-1/4 text-xs font-bold uppercase">Address:</p>
              <p className="text-sm font-light">{customer.contact.address + " " + customer.contact.address2}</p>
            </div>
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="w-1/4 text-xs font-bold uppercase">Email:</p>
              <p className="text-sm font-light lowercase">{customer.contact.email}</p>
            </div>
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="w-1/4 text-xs font-bold uppercase">City / State:</p>
              <p className="text-sm font-light">{customer.contact.city + (customer.contact.state.length > 0 ? ", " : "") + customer.contact.state}</p>
            </div>
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="w-1/4 text-xs font-bold uppercase">Phone Number:</p>
              <p className="text-sm font-light">
                {customer.contact.phoneNumber && customer.contact.phoneNumber.length === 10
                  ? `(${customer.contact.phoneNumber.slice(0, 3)}) ${customer.contact.phoneNumber.slice(3, 6)}-${customer.contact.phoneNumber.slice(6, 10)}`
                  : ""}
              </p>
            </div>
            <div className="flex flex-row items-center justify-start gap-1 ">
              <p className="w-1/4 text-xs font-bold uppercase">ZIP Code:</p>
              <p className="text-sm font-light">{customer.contact.zip}</p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start w-full px-3 pt-1 pb-4 mt-3 border-b border-gray-300">
            <div className="flex flex-row items-center justify-between w-full">
              <h1 className="text-gray-500 uppercase md:text-xl font-base">A/R Information</h1>
              <button
                className="px-8 py-2 text-xs font-bold uppercase duration-150 rounded-full bg-slate-300 text-slate-800 hover:bg-gray-500/50"
                onClick={() => navigate(`/customers/${id}/ar`)}
              >
                {authState.user.functionCategory === "technician" ? "View" : "View / Edit"}
              </button>
            </div>
          </div>
          <div className="grid w-full grid-cols-1 grid-rows-1 gap-1 px-3 pb-2 my-2 border-b border-gray-300 md:grid-cols-2">
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="w-1/4 text-xs font-bold uppercase">Name:</p>
              <p className="text-sm font-light">{customer.arData.name.length > 0 ? customer.arData.name : "Not provided"}</p>
            </div>
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="w-1/4 text-xs font-bold uppercase">Address:</p>
              <p className="text-sm font-light">
                {customer.arData.address.length > 0 ? customer.arData.address + " " + customer.arData.address2 : "Not provided"}
              </p>
            </div>
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="w-1/4 text-xs font-bold uppercase">Email:</p>
              <p className="text-sm font-light">{customer.arData.email.length > 0 ? customer.arData.email : "Not provided"}</p>
            </div>
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="w-1/4 text-xs font-bold uppercase">City / State:</p>
              <p className="text-sm font-light">{customer.arData.city + (customer.arData.state.length > 0 ? ", " : "Not Provided") + customer.arData.state}</p>
            </div>
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="w-1/4 text-xs font-bold uppercase">Phone Number:</p>
              <p className="text-sm font-light">
                {customer.arData.phoneNumber && customer.arData.phoneNumber.length === 10
                  ? `(${customer.arData.phoneNumber.slice(0, 3)}) ${customer.arData.phoneNumber.slice(3, 6)}-${customer.arData.phoneNumber.slice(6, 10)}`
                  : "Not Provided"}
              </p>
            </div>
            <div className="flex flex-row items-center justify-start gap-1 ">
              <p className="w-1/4 text-xs font-bold uppercase">ZIP Code:</p>
              <p className="text-sm font-light">{customer.arData.zip.length > 0 ? customer.arData.zip : "Not Provided"}</p>
            </div>
            <div className="flex flex-row items-center justify-start gap-1 ">
              <p className="w-1/4 text-xs font-bold uppercase">Credit Limit:</p>
              <p className="text-sm font-light">{formatCurrency(customer.arData.creditLimit)}</p>
            </div>
            <div className="flex flex-row items-center justify-start gap-1 ">
              <p className="w-1/4 text-xs font-bold uppercase">Payment Terms:</p>
              <p className="text-sm font-light">{terms[customer.arData.terms]}</p>
            </div>
          </div>
          {authState.user.functionCategory !== "technician" && (
            <>
              <div className="flex flex-row items-center justify-start w-full px-3 pt-1 pb-4 mt-3 border-b border-gray-300">
                <div className="flex flex-row items-center justify-between w-full">
                  <h1 className="text-xl text-gray-500 uppercase font-base">Customer Defaults</h1>
                  <button
                    onClick={() => navigate(`/customers/${id}/defaults`)}
                    className="px-8 py-2 text-xs font-bold uppercase duration-150 rounded-full bg-slate-300 text-slate-800 hover:bg-gray-500/50"
                  >
                    {authState.user.functionCategory === "technician" ? "View" : "View / Edit"}
                  </button>
                </div>
              </div>
              <div className="grid w-full grid-cols-2 grid-rows-4 px-3 py-4 border-b border-gray-300">
                <div className="flex flex-row items-center justify-start gap-1">
                  <p className="w-1/4 text-xs font-bold uppercase">Labor Discount:</p>
                  <p className="text-sm font-light">{customer.defaults.laborDiscount}</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <p className="w-1/4 text-xs font-bold uppercase">Contact Preference:</p>
                  <p className="text-sm font-light">Email</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <p className="w-1/4 text-xs font-bold uppercase">Parts Markup:</p>
                  <p className="text-sm font-light lowercase">{customer.defaults.partsMarkup}.00%</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <p className="w-1/4 text-xs font-bold uppercase">Document Detail:</p>
                  <p className="text-sm font-light capitalize">{customer.defaults.docDetail}</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1">
                  <p className="w-1/4 text-xs font-bold uppercase">Shop Fees:</p>
                  <p className="text-sm font-light">{customer.defaults.shopFees}.00%</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1 ">
                  <p className="w-1/4 text-xs font-bold uppercase">Taxable:</p>
                  <p className="text-sm font-light">Yes</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1 ">
                  <p className="w-1/4 text-xs font-bold uppercase">Warranty Provider:</p>
                  <p className="text-sm font-light">{customer.defaults.warrantyProvider ? "Yes" : "No"}</p>
                </div>
                <div className="flex flex-row items-center justify-start gap-1 ">
                  <p className="w-1/4 text-xs font-bold uppercase">Sales Tax ZIP:</p>
                  <p className="text-sm font-light">{customer.defaults.salesTaxZip.length === 5 ? customer.defaults.salesTaxZip : "Not provided"}</p>
                </div>
              </div>
            </>
          )}
          <div className="flex flex-row items-center justify-start w-full px-3 pt-1 pb-4 mt-3 border-b border-gray-300">
            <div className="flex flex-row items-center justify-between w-full">
              <h1 className="text-sm font-bold text-red-500 uppercase">DANGER ZONE</h1>
              <button
                onClick={() => setConfirmDelete(true)}
                className="px-8 py-2 text-xs font-bold text-red-800 uppercase duration-150 bg-red-300 rounded-full hover:bg-red-500/30"
                disabled={authState.user.functionCategory === "technician"}
              >
                Delete Customer
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col items-start justify-start w-full col-span-12 gap-2 px-5 py-3 border rounded-lg shadow-md md:col-span-3 bg-slate-300/40 border-slate-300/60">
        <div className="flex flex-row items-center justify-between w-full pb-2 my-2 border-b border-gray-300">
          <h1 className="px-2 text-xl font-light leading-10 md:text-2xl">Notes</h1>
        </div>
        <div className="flex flex-col items-center justify-start w-full h-full gap-3">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : customer.notes.length > 0 ? (
            customer.notes.map((note) => <CustomerNote note={note} customerId={customer.customerId} employees={employees} reload={() => reloadData()} />)
          ) : (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">No notes found</p>
          )}
        </div>
        <div className="flex flex-col items-center justify-center w-full mt-2">
          {noteField ? (
            <NoteField
              closeField={(hasNew) => {
                setNoteField(false);
                if (hasNew) {
                  reloadData();
                }
              }}
              id={id}
            />
          ) : (
            <button
              onClick={() => setNoteField(true)}
              className="px-5 py-3 text-xs font-bold uppercase duration-150 rounded-md bg-slate-300 text-slate-800 hover:bg-gray-500/50"
            >
              Add a new note
            </button>
          )}
        </div>
      </div>
      {renderChangeCodeModal()}
      {renderChangeCompanyModal()}
      {renderConfirmDelete()}
    </div>
  );
};

export default CustomerID;
